<template>
  <div class="mine-section">
    <Header :showback="false">
      <template slot="title">我的</template>
    </Header>
    <div class="minecontent">
      <div class="userInfo">
        <div class="user">
          <img class="userhead" :src="require('@/assets/images/logo.png')" />
          <div class="usertxt">
            <div class="username">
              {{ userInfo.name }}
        <!--      <div class="realbtn" v-if="userInfo.is_auth === 1">已实名</div>
              <div class="realbtn" v-if="userInfo.is_auth === 2">认证中</div>-->
              <!--<div class="realbtn4" >天使</div>-->
              <!--v-if="userInfo.is_teacher === 1"-->
              <img src="../../assets/images/tianshi.png" v-if="userInfo.is_angel === 1" style="width:30px;display: inline-block;margin: 0 5px;" alt="">
              <img src="../../assets/images/trade.png" v-if="userInfo.is_trade === 1" style="width:30px;display: inline-block;margin: 0 5px 0 0;" alt="">
              <div class="realbtn1" v-if="userInfo.is_teacher === 1">讲师</div>
              <div
                class="realbtn2"
                v-if="userInfo.is_gu === 1 && userInfo.partner_level_id === 3"
              >
                金牌
              </div>
              <div
                class="realbtn3"
                v-if="userInfo.is_gu === 1 && userInfo.partner_level_id === 2"
              >
                银牌
              </div>
            </div>
            <div class="userID">
              ID：{{ userInfo.id }}
              <span style="margin-left: 30px" v-if="userInfo.mobile"
                >手机号：{{ userInfo.mobile }}</span
              >
            </div>
          </div>
          <div class="dataBtn" @click="clickInfomation">
            完善资料 <br/>
            领取空投
          </div>
        </div>
        <div class="card">
          <div class="zican" @click="toTransactions">
            <div class="num">
              {{ parseFloat(statistics.total).toFixed(2) || 0 }}
            </div>
            <div class="txt">资产(USD)</div>
          </div>
          <div class="zican" @click="clickContribution">
            <div class="num">
              {{ parseFloat(statistics.active).toFixed(2) || 0 }}
            </div>
            <div class="txt">活跃值</div>
          </div>
          <div class="zican" @click="clickDevoter">
            <div class="num">
              {{ parseFloat(statistics.devote).toFixed(2) || 0 }}
            </div>
            <div class="txt">共识度</div>
          </div>
        </div>
        <div class="service">
          <div class="title">我的服务</div>
          <div class="btnmenu">
            <div
              v-for="item in menuList"
              @click="clickMenu(item.name)"
              :key="item.name"
              class="menu"
            >
              <img class="menuimg" :src="item.img" />
              <div class="menuname">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="shareinvite" @click="clickShareInvite">
          <img
            class="shareimg"
            :src="require('@/assets/images/mine/invite.png')"
          />
        </div>
      </div>
    </div>
    <infomation ref="infomation"></infomation>
  </div>
</template>

<script>
import metamaskConfig from "../../components/connection.js";
import { ethers } from "../../components/ethers-5.1.esm.min.js";
import Header from "../../components/header.vue";
import Infomation from "./modal/infomation.vue";
import { myStatistics, getUserInfo } from "@/request/api";
import { Toast } from "vant";
let defaultAccount, networkVersion;
import store from "../../store";

export default {
  components: {
    Header,
    Infomation,
  },
  data() {
    return {
      userInfo: {},
      address: "",
      statistics: {
        devote: 0,
        total: "0.00",
        active: 0,
      },
      menuList: [
        {
          name: "乐淘",
          img: require("@/assets/images/mine/store.png"),
        },
        {
          name: "团队",
          img: require("@/assets/images/mine/team.png"),
        },
        {
          name: "操作指南",
          img: require("@/assets/images/mine/jiedai.png"),
        },
        {
          name: "在线反馈",
          img: require("@/assets/images/mine/onlineback.png"),
        },
        {
          name: "游戏",
          img: require("@/assets/images/mine/money.png"),
        },
          {
              name: "公益",
              img: require("@/assets/images/mine/jie.png"),
          },
        {
          name: "众筹",
          img: require("@/assets/images/mine/zongcou.png"),
        },
        {
          name: "旅游",
          img: require("@/assets/images/mine/travel.png"),
        },
          {
              name: "btc3.0",
              img: require("@/assets/images/mine/m_btc.png"),
          },
          {
              name: "元宇宙",
              img: require("@/assets/images/mine/m_meta.png"),
          },
          {
              name: "铭文",
              img: require("@/assets/images/mine/m_mingwen.png"),
          },
          {
              name: "志愿者",
              img: require("@/assets/images/mine/m_zhiyuan.png"),
          },
      ],
    };
  },
  mounted() {},
  activated() {
    this.checkLogin();
  },
  methods: {
    getUserInfo() {
      getUserInfo({ address: this.address }).then((res) => {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("level_id");
        sessionStorage.setItem("user", JSON.stringify(res.data));
        sessionStorage.setItem("level_id", res.data.level_id);
        this.userInfo = res.data;
      });
    },
    async checkLogin() {
      try {
        if (metamaskConfig.isMetamaskConnected) {
          ethereum.autoRefreshOnNetworkChange = false;
          networkVersion = await metamaskConfig.getNetworkId();
          if (networkVersion == 1) {
            //以太坊主网
            console.log("ETH");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 42) {
            //以太坊测试网  K
            console.log("ETH");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 128) {
            //HECO主网
            console.log("HT");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 256) {
            //HECO测试网
            console.log("HT");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 56) {
            //币安主网
            console.log("BNB");
          } else {
            console.log("其他");
            Toast("请使用币安智能链链接");
            return false;
          }
          defaultAccount = await metamaskConfig.connectToAccount();
          if (defaultAccount[0]) {
            this.address = defaultAccount[0];
            sessionStorage.removeItem("address");
            sessionStorage.setItem("address", this.address);
            if (this.address && sessionStorage.getItem(this.address)) {
              this.getmyStatistics();
              this.getUserInfo();
            }
          }
        } else {
          Toast("请使用币安智能链链接");
        }
      } catch (e) {
        console.log("1111");
      }
    },
    noShow() {
      Toast("即将开放");
    },
    toTransactions() {
      if (this.address && sessionStorage.getItem(this.address)) {
        this.$router.push({
          path: "/home/mine/transactions",
        });
      }
    },
    getmyStatistics() {
      myStatistics({ address: this.address }).then((res) => {
        this.statistics = res.data;
      });
    },
    clickContribution() {
      if (this.address && sessionStorage.getItem(this.address)) {
        this.$router.push({
          path: "/home/mine/contribution",
        });
      }
    },
    clickDevoter() {
      if (this.address && sessionStorage.getItem(this.address)) {
        this.$router.push({
          path: "/home/mine/devoteLog",
        });
      }
    },
    async clickMenu(menuname) {
      // no-unused-vars
      if (menuname === "团队") {
        this.$router.push({
          path: "/home/mine/team",
        });
      }
      else if (menuname === "btc3.0") {
          location.href=contractConfig.idoUrl
      }
      else if (menuname === "操作指南") {
        this.$router.push({
          path: "/home/mine/operation",
        });
      }else if (menuname === "游戏") {
          location.href=contractConfig.gameUrl+'/#/?address='+this.address
      }
      else if (menuname === "公益") {
          this.noShow();
        /*  this.$router.push({
              path: "/home/mine/tradeCoin",
          });*/
          // location.href=contractConfig.hashUrl
      }
      else if (menuname === "在线反馈") {
        this.$router.push({
          path: "/home/mine/onlineserve",
        });
      } else if (menuname === "乐淘") {
          // this.noShow();
        this.$router.push({
          path: "/mall/mallhome",
        });
      } else {
        this.noShow();
      }
    },
    clickShareInvite() {
      this.$router.push({
        path: "/home/index/bindinvite",
      });
    },
    clickInfomation() {
      this.$refs.infomation.preview();
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.minecontent {
  padding: 0px 32px;
}

.userInfo {
  .user {
    display: flex;
    justify-content: space-between;
    .userhead {
      width: 88px;
      height: 88px;
      border-radius: 8px;
    }
    .usertxt {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .username {
        font-weight: 400;
        font-size: 28px;
        /* #F5FFF9 */
        color: #d6ffe3;
        display: flex;
        align-items: center;
        .realbtn {
          display: inline-block;
          width: 128px;
          height: 40px;
          background: #27c7cc;
          border-radius: 8px;
          font-size: 20px;
          line-height: 45px;
          text-align: center;
          color: #0b484a;
          margin-right: 10px;
        }
        .realbtn1 {
          display: inline-block;
          width: 64px;
          height: 45px;
          background: #22ce2e;
          border-radius: 8px;
          font-size: 20px;
          line-height: 45px;
          text-align: center;
          color: #0b484a;
        }
        .realbtn2 {
          display: inline-block;
          width: 64px;
          height: 45px;
          margin-left: 5px;
          background: #fede18;
          border-radius: 8px;
          font-size: 20px;
          line-height: 45px;
          text-align: center;
          color: #0b484a;
        }
        .realbtn3 {
          display: inline-block;
          width: 64px;
          height: 45px;
          margin-left: 5px;
          background: #fbfbfb;
          border-radius: 8px;
          font-size: 20px;
          line-height: 45px;
          text-align: center;
          color: #0b484a;
        }
        .realbtn4 {
          display: inline-block;
          width: 64px;
          height: 45px;
          margin:0 5px;
          background: #ED0FE8;
          border-radius: 8px;
          font-size: 20px;
          line-height: 45px;
          text-align: center;
          color: #fff;
        }
      }
      .userID {
        font-weight: 400;
        font-size: 16px;
        color: #8ba695;
      }
    }
    .dataBtn {
      font-weight: 400;
      font-size: 28px;
      text-align: center;
      color: #27cc7f;
    }
  }
  .card {
    margin-top: 32px;
    width: 686px;
    height: 152px;
    background: #29332d;
    border-radius: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .num {
      font-weight: 400;
      font-size: 44px;
      text-align: center;
      color: #b6d9c3;
    }
    .txt {
      font-weight: 400;
      font-size: 24px;
      color: #8ba695;
      text-align: center;
    }
  }
  .service {
    margin-top: 32px;
    width: 686px;
    height: 538px;
    background: #29332d;
    border-radius: 24px;
    .title {
      font-weight: 600;
      font-size: 32px;
      color: #b6d9c3;
      padding-top: 24px;
      margin-left: 24px;
    }
    .btnmenu {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .menu {
        width: 160px;
        height: 136px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        .menuimg {
          width: 48px;
          height: 48px;
        }
        .menuname {
          font-size: 24px;
          color: #b6d9c3;
        }
      }
    }
  }
  .shareinvite {
    margin-top: 24px;
    margin-bottom: 10px;
    width: 686px;
    /*height: 580px;*/
    border-radius: 24px;
    opacity: 1;
    /*background: rgba(39, 67, 21, 1);*/
    .shareimg {
      width: 100%;
      border-radius: 24px;
    }
  }
}
</style>