<template>
  <div class="scene-section">
    <Header :showback="false">
      <template slot="title">环保</template>
    </Header>
    <div>
      <div class="scene-tab">
        <img
          src="../../assets/images/mine/game_back.jpg"
          alt=""
          class="scene-tab-img"
        />
      </div>
      <!--<div class="scene-tab" @click="toQuestion()">
                <img src="../../assets/images/game1.png" alt="" class="scene-tab-img">
                <div class="scene-tab-title3">每天学习</div>
                <div class="scene-tab-title">环保知识</div>
            </div>
            <div class="scene-tab" @click="noShow">
                <img src="../../assets/images/game00.png" alt="" class="scene-tab-img">
                <div class="scene-tab-title1">即将开放</div>
                <div class="scene-tab-title2">coming soon</div>
            </div>-->
      <div class="systemcon">
        <van-popup v-model="showIntroduce" :close-on-click-overlay="false">
          <div class="introduce">
            <div class="content">
              <!--<div class="title">-->
                <!--Environmental Ecosystem<br/>-->
                <!--环保生态系统</div>-->
              <div class="introducetxt">
                <div class="title">Environmental Volunteer Declaration</div>
                I am an environmental volunteer,
                For the survival and sustainable development of mankind, we swear:
                Use actions to adjust your lifestyle;
                Use wisdom to pay attention to changes in survival;
                Use conscience to prevent environmental damage;
                Fulfill environmental protection obligations with responsibility;
                Inspire every citizen with spirit;
                Use your power to supervise your own family members;
                Use awareness to lead the trend of environmental protection;
                Inspire everyone's enthusiasm with love;
                Let ecological cultural ideas lead the world;
                Let environmentally friendly seeds take root, sprout and bear fruit;
                Let the tree of the earth be evergreen.<br/>
                <div class="title">环保志愿者宣言</div>
                我是一名环保志愿者，
                为了人类的生存和可持续发展，我们宣誓:
                用行动调整生活方式;
                用智慧关注生存变化;
                用良知阻止破坏环境的现象;
                用责任履行环保义务;
                用精神鼓舞每一个公民;
                用权利监督自己的家庭成员;
                用意识引领环保潮流;
                用爱心激发每个人的热情;
                让生态文化思想领先世界;
                让环保的种子生根、发芽、结果;
                让地球之树常青。
              </div>
              <van-count-down
                      ref="countDown"
                      :time="time"
                      format="ss"
                      @finish="finish"
              />
            </div>
          </div>
        </van-popup>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import { Toast } from "vant";
import { questionList, submitAnswer } from "@/request/api";
import $ from "jquery";
export default {
  name: "",
  components: {
    Header,
  },
    data() {
        return {
            time: 10 * 1000,
            showIntroduce: true,
            refreshing: false,
            loading: false,
            finished: false,
            recordList: [],
            checked: [],
            address: "",
            success: [],
            showResult: false,
            score: 0,
            answerList: [
                { cate_id: 1, name: "厨余垃圾", name_en: "Kitchen waste" },
                { cate_id: 2, name: "可回收垃圾", name_en: "recyclable trash" },
                { cate_id: 3, name: "有害垃圾", name_en: "hazardous waste" },
                { cate_id: 4, name: "其他垃圾", name_en: "other garbage" },
            ],
        };
    },
    activated() {
        this.showIntroduce = true;
        this.time = 18 * 1000;
        this.$refs.countDown.reset();
        this.$refs.countDown.start();
        this.address = sessionStorage.getItem("address");
        this.checked = [];
        this.success = [];
    },
    methods: {
        finish() {
            this.showIntroduce = false;
            const params = {
                address: this.address,
                score: 100,
            };
            submitAnswer(params).then((res) => {
                if (res.code == 200) {
                    Toast("参与成功");
                } else {
                    Toast("参与成功");
                }
            });
        },
        closeShowResult() {
            this.showResult = false;
        },
    },
};
</script>
<style scoped lang="scss">
.scene-section {
  background: #0c1913;
  min-height: 100vh;
}
.systemcon {
  ::v-deep .van-popup {
    border-radius: 6px;
  }
}
::v-deep .van-count-down {
  font-size: 30px;
  color: white;
  text-align: center;
}
.introduce {
  width: 700px;
  height: 100%;

  background-color: #078538;
  .content {
    padding: 24px;
    color: white;
    font-weight: bold;
    .title {
      text-align: center;
      font-size: 38px;
    }
    .introducetxt {
      margin-top: 15px;
      font-size: 30px;
      line-height: 50px;
    }
  }
}
.scene-tab {
  position: relative;
  .scene-tab-img {
    width: 100%;
  }
  .scene-tab-title {
    position: absolute;
    color: #e0c147;
    font-size: 45px;
    font-weight: bold;
    top: 48%;
    width: 40%;
    left: 30%;
    text-align: center;
  }
  .scene-tab-title1 {
    position: absolute;
    color: #e0c147;
    font-size: 45px;
    font-weight: bold;
    top: 30%;
    width: 40%;
    left: 30%;
    text-align: center;
  }
  .scene-tab-title2 {
    position: absolute;
    color: #e0c147;
    font-size: 45px;
    font-weight: bold;
    top: 38%;
    width: 40%;
    left: 30%;
    text-align: center;
  }
  .scene-tab-title3 {
    position: absolute;
    color: #e0c147;
    font-size: 35px;
    font-weight: bold;
    top: 40%;
    width: 40%;
    left: 30%;
    text-align: center;
  }
}
</style>