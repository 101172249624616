<template>
  <div class="header" :style="customstyle">
    <div class="header-bg">
      <div class="header-content">
        <div class="header-title">
          <slot name="title">ATT</slot>
        </div>
        <div class="header-back" v-if="showback">
          <div class="back" @click="handleTitleBack">
            <i class="iconfont icon-arrowthin"></i>
          </div>
        </div>
        <div class="devote_toRank" v-if="showRank" @click="toRank">
          贡献排行
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showback: {
      type: Boolean,
      default: true,
    },
    showRank: {
      type: Boolean,
      default: false,
    },
    customstyle: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    toRank() {
      this.$router.push({
        path: "/home/mine/devoterank",
      });
    },
    handleTitleBack() {
      this.$router.back();
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  height: 100px;
  background: linear-gradient(to right, #102b1d, #102b1d);
  .header-bg {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    .devote_toRank {
      position: absolute;
      right: 32px;
      top: 32px;
      height: 100%;
      z-index: 9;
      color: #5094d5;
      font-size: 16px;
    }
  }
}
</style>